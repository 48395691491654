import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginServiceService } from '../../services/login-service.service';
import {FormBuilder,FormGroup, Validators,FormControl} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  currentYear = new Date().getFullYear();
  errorMessage: string;
  successMessage: string;
  resetToken: null;
  login_id:any;
  CurrentState: any;
  IsResetFormValid = true;
  session_data:any;
  validatingForm: any;
  isFetching =false;
  userdetail = [];
  isDisabled = true;
  errormessage:any;

  userinfo = {
   
    userid:'',    
    oldpassword : '',
    newpassword : '',
    comfirmpassword:'',
    token:'',
    email:''
  }
  myobj = {
    token: ''
   
  }

  NewshowPassword: boolean = false;ConfirmshowPassword: boolean = false;
  
  constructor(private loginservice: LoginServiceService, private router: Router, private route: ActivatedRoute) {
      // this.route.queryParams.subscribe(params => {
      //   this.resetToken = params['token'];
      //   console.log('gautam11 ', this.resetToken);
      //   //this.VerifyToken();
      // });
   }

  ngOnInit(): void {
    
//this.errormessage='hello';    
   // this.session_data= JSON.parse(sessionStorage.getItem('petronetUserData'));
  this.route.queryParams.subscribe(params => {
        this.userinfo.email = params['email'];
        console.log('gautam11 ', this.userinfo);
        //this.VerifyToken();
      });
    this.validation();
    
  }

  
 //validate the control value of text boxes and drop down box.
 validation() 
 { 
  //const namealphbaetPattern ="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$";
  const namealphbaetPattern ="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&!(*)?+=])(?=\\S+$).{8,20}$";
 
   this.validatingForm = new FormGroup({
    newpassword: new FormControl(null,[ Validators.required,Validators.pattern( namealphbaetPattern)]),
    comfirmpassword: new FormControl(null,[ Validators.required]),
    
   },this.passwordConfirming);
 }

 passwordConfirming(group: FormGroup) {
  const password = group.get('newpassword').value;
  const confirmPassword = group.get('comfirmpassword').value;

  if (password !== confirmPassword) {
    group.get('comfirmpassword').setErrors({'noMatch': true });
    return {  invalid: true};
  } else {
    group.get('comfirmpassword').setErrors(null);
    return null;
  }
}


 
//  passwordConfirming(c: FormGroup) {

//   if (c.get('newpassword').value !== c.get('comfirmpassword').value) {
//     c.get('comfirmpassword').setErrors({ 'noMatch': true });
//     return { invalid: true };
//   }
// }

display()
{
  if(this.userinfo.newpassword!="")
  {
    this.isDisabled = !this.isDisabled;
  }
}

ResetPassword()
{
  if(this.validatingForm.invalid)
  {
   
    this.validatingForm.controls['newpassword'].markAsTouched(),
    this.validatingForm.controls['comfirmpassword'].markAsTouched()
   
  }
else{

  this.route.queryParams.subscribe(params => {
   this.userinfo.token = params['token'];
   //this.userinfo.email = params['email'];
    //this.login_id =params['ki'];
    //console.log('gautam ', this.userinfo);


  });
  //this.myobj.token =this.resetToken;
  this.loginservice.resetpassword(this.userinfo).subscribe((res: any) => {
    console.log('owl',res.result[0].sp_updatepassword)
    if(res.result=='3')
    {
      //console.log('if')
      this.errorMessage='Your token is expired.'
      Swal.fire({
        icon: 'success',
        //title: 'Thank you for subscribe!',
        html: 'Your token is expired.',
        showConfirmButton: true,
        confirmButtonColor: 'var(--theme-color1)',
        // });

      });
    }
    //This token is invalid
    else if(res.result=='4')
    {
      //this.errorMessage='Taken is invalid.'
      Swal.fire({
        icon: 'success',
        //title: 'Thank you for subscribe!',
        html: 'Token is invalid.',
        showConfirmButton: true,
        confirmButtonColor: 'var(--theme-color1)',
        // });

      });
    }
    //Using token once again
      else if(res.result=='2')
      {
        //this.errorMessage='Taken is invalid.'
        Swal.fire({
          icon: 'success',
          //title: 'Thank you for subscribe!',
          html: 'This token is already used. Kindly generate once again.',
          showConfirmButton: true,
          confirmButtonColor: 'var(--theme-color1)',
          // });
  
        });

    }

    //for successfully token used
    else if (res.result=='1')
      {
        //this.errorMessage='Taken is invalid.'
        Swal.fire({
          icon: 'success',
          //title: 'Thank you for subscribe!',
          html: 'New password updated successfully.',
          showConfirmButton: true,
          confirmButtonColor: 'var(--theme-color1)',
          // });
  
        });

    }
    
  })
}
  //console.log('popop', this.userinfo)
}

toggleNewPasswordVisibility() {
  this.NewshowPassword = !this.NewshowPassword;
}

toggleConfirmPasswordVisibility() {
  this.ConfirmshowPassword = !this.ConfirmshowPassword;
}



}
