
<div class="loginbg">
<div class="container">
      <div class="row">
        <div class="col-md-12">
            <div class="text-center pt-5">
                <img src="./assets/images/logo_1.png" class="">
            </div>
        
            <div class="mt-3 text-center">
                <p *ngIf="message1" style="font-size: 16px; font-weight:500;">Thank you for accepting the invitation. <br/> You may now login to your account with this email and begin using Chalk.</p>
                <p *ngIf="message2" style="font-size: 16px; font-weight:500;">You've already accepted the invitation.<br/>  You may login to your account with this email and begin using Chalk.</p>
        
                
            </div>
        </div>
      </div>

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
            <a [routerLink]="['/login']" class="btn btn-login">Login</a>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>
</div>
      
<div id="spinner">
    <div class="clock"></div>
    <p id="spinnertext">
            <label>Processing, Please wait...</label>
    </p>

</div>


