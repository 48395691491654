import Swal from 'sweetalert2';

export const showRedAlert = Swal.mixin({
 // toast: true,
  position: 'center',
  showConfirmButton: false,
  allowOutsideClick:true,
 showCloseButton: true,
 background: 'rgb(217, 83, 79)',
  color: 'white',
  showClass: {
    popup: 'toatmessege'
  },

  
  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer);
  //   toast.addEventListener('mouseleave', Swal.resumeTimer);
  // },
});

export const showSuccessAlert = Swal.mixin({
 // toast: true,

  position: 'center',
  showConfirmButton: false,
  allowOutsideClick:true,
  showCloseButton: true,
  background: 'rgb(92, 184, 92)',
  color: 'white',
  showClass: {
    popup: 'toatmessege'
  },

  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer);
  //   toast.addEventListener('mouseleave', Swal.resumeTimer);
  // },
});

export const showWarningAlert = Swal.mixin({
 // toast: true,
  position: 'center',
  showConfirmButton: false,
  allowOutsideClick:true,
  showCloseButton: true,
  background: 'rgb(236,151,31)',
  color: 'white',
  showClass: {
    popup: 'toatmessege'
  },

  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer);
  //   toast.addEventListener('mouseleave', Swal.resumeTimer);
  // },
});



export const showConfirmAlert = Swal.mixin({
  // toast: true,

   position: 'center',
   showConfirmButton: true,
   showCancelButton: true,
   showCloseButton: false,
   allowOutsideClick:true,
   confirmButtonText: 'Yes',
   showClass: {
    popup: 'confirm-messege'
  },
   icon: 'warning',
   iconColor:'white',
   background: 'rgb(236,151,31)',
   color: 'white',
 
  
 });
 


export const showInfoAlert = Swal.mixin({
  //toast: true,
  position: 'center',
  showConfirmButton: false,
  allowOutsideClick:true,
  showCloseButton: true,
  background: 'rgb(91,192,222)',
  color: 'white',
  showClass: {
    popup: 'toatmessege'
  },

  
  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer);
  //   toast.addEventListener('mouseleave', Swal.resumeTimer);
  // },
});
