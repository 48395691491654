<div class="loginbg">
        <div class="container">
                <br/> <br/>
        <div class="login-wrapper">
                <div class="text-center">
                        <img src="./assets/images/logo_1.png" />
                        <br/>  
                        <hr/>
                        <h5>Forgot password </h5>
                        </div>
                        <br/>  

                        <form [formGroup]="validatingForm" class="form">
                                        
                
                                <div class="row px-3">
                                    <label class="mb-1">Email </label>
                        
                                    <input type="email" class="form-control" [(ngModel)]="EmailObj.emailid"  placeholder="Enter a valid email Id" formControlName="login_id">
                                    <div  class="mandatory" *ngIf="validatingForm.get('login_id').hasError('required') && validatingForm.get('login_id').touched">Email Required.</div>
                                    <div  class="mandatory" *ngIf="validatingForm.get('login_id').hasError('pattern') && validatingForm.get('login_id').touched">Email not valid.</div>
                                </div>
                        
                        
                        
                                <div class="row mb-3 mt-3 px-3">
                                    <div class="col-md-12 text-center">
                                            <button type="submit" class="btn btn-custom-gold-defult text-center py-2 "  (click)="submit()">Submit</button> &nbsp;
                                    
                                            &nbsp;
                                            <button class="btn btn-custom-gold-defult text-center py-2 " [routerLink]="['/login']">Back</button>
                                    </div>
                                </div>
                        
                            </form>
                                



             
                <div class="row mt-4">
                        <div class="col-12 text-center">
                                <small class="text-secondary"> &copy; 2020-{{currentYear}}<span  class="text-defult-theme"> Chalk Technologies Inc.</span> All Rights Reserved.</small>
                        </div>
                
                </div>
                
        </div>
                </div>
</div>

<div  id="spinner">
<div class="clock"></div>
    <p id="spinnertext">
            <label>Processing, Please wait...</label>
        </p>
        
    </div>