<div class="loginbg">
    <div class="container">
          <br/> <br/>
        <div class="login-wrapper">
            <div class="text-center">
                <img src="./assets/images/logo_1.png" />
                <hr/>
                <h5>Reset password for the user: {{userinfo.email}}</h5>
            </div>
            <br/>
            <form [formGroup]="validatingForm">

                <div class="form-group">
                    <label class="form-control-label">New Password<span class="mandatory">*</span></label>
                    <div class="d-flex">
                        <input type="{{ NewshowPassword ? 'text' : 'password' }}" id="inputPassword" class="form-control" placeholder="Enter new password"
                        formControlName="newpassword" [(ngModel)]="userinfo.newpassword">
                        <span class="passwordtoggle" (click)="toggleNewPasswordVisibility()"><i class="{{ NewshowPassword ? 'fa fa-eye' : 'fa fa-eye-slash' }}" aria-hidden="true"></i></span>
                    </div>
                    <password-strength-meter [password]="userinfo.newpassword"></password-strength-meter>
                    <div class="mandatory"
                        *ngIf="validatingForm.get('newpassword').hasError('required') && validatingForm.get('newpassword').touched">
                        New Password is a required field.</div>

                    <div class="mandatory"
                        *ngIf="validatingForm.get('newpassword').hasError('pattern') && validatingForm.get('newpassword').touched">
                        Must contain atleast one lowercase letter, one uppercase letter, one special character, one
                        number, minimum 8 and maximum 20 character and only valid special characters !, @, #, $, %, ^,
                        &, *, (, ), +, =, ? are allowed. </div>

                </div>
                <div class="form-group">
                    <label class="form-control-label ">Confirm New Password<span class="mandatory">*</span></label>
                     <div class="d-flex">
                        <input type="{{ ConfirmshowPassword ? 'text' : 'password' }}" class="form-control" placeholder="Enter confirm new password"
                        formControlName="comfirmpassword" [(ngModel)]="userinfo.comfirmpassword">
                        <span class="passwordtoggle" (click)="toggleConfirmPasswordVisibility()"><i class="{{ ConfirmshowPassword ? 'fa fa-eye' : 'fa fa-eye-slash' }}" aria-hidden="true"></i></span>
                     </div>
                    <password-strength-meter [password]="userinfo.comfirmpassword"></password-strength-meter>
                    <div class="mandatory"
                        *ngIf="validatingForm.get('comfirmpassword').hasError('required') && validatingForm.get('comfirmpassword').touched">
                        Confirm Password required.</div>
                    <div class="mandatory"
                        *ngIf="validatingForm.get('comfirmpassword').hasError('noMatch') && validatingForm.get('comfirmpassword').touched">
                        Password does not match.</div>


                </div>



                <div class="form-group">
                    <div class="d-flex justify-content-center mb-2">
                        <button class="btn btn-custom-gold-defult py-2 px-3" [disabled]="!validatingForm.valid" (click)="ResetPassword()">Reset Password</button> &nbsp;
                        &nbsp;

                    </div>
                </div>



            </form>

            <div id="message">
                <span> <b>Password must contain the following:</b></span>
                <ul>
                    <li>A lowercase letter.</li>
                    <li>A capital (uppercase) letter.</li>
                    <li>A number.</li>
                    <li>A special character.</li>
                    <li>Minimum 8 and Maximum 20 character.</li>
                    <li>Valid special characters are !, @, #, $, %, ^, &, *, (, ), +, =, ? </li>

                </ul>


            </div>

            <div class="row mt-5">
                <div class="col-12 text-center">
                    <small class="text-secondary"> &copy; 2020-{{currentYear}} <span class="text-defult-theme"> Chalk
                            Technologies Inc.</span>. All Rights Reserved.</small>
                </div>

            </div>
        </div>


  

    </div>
</div>















<!-- <section class="py-0">
    <div class="row bg-white">
            <div class="col-md-12 p-3 text-dark">
                    <h3 class="h6 text-uppercase mb-0"> <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i>  Reset Password</h3>
            </div>
        
        </div>
    <div class="row mt-3">
    <div class="col-lg-12 mb-5">
         <div class="card">
          
            <div class="card-body">
                <form [formGroup]="validatingForm">
                        <div class="row">
                                <div class="col-md-4 offset-md-4">
                                 
                                  
                                    <div class="form-group">
                                        <label class="form-control-label text-uppercase">New Password<span class="mandatory">*</span></label>
                                            <input type="password" id="inputPassword" class="form-control"  placeholder="" formControlName="newpassword" [(ngModel)]="userinfo.newpassword" >
                                            <div  class="mandatory" *ngIf="validatingForm.get('newpassword').hasError('required') && validatingForm.get('newpassword').touched">New Password is a required field.</div>
                                           
                                        </div>
                                    <div class="form-group">
                                        <label class="form-control-label text-uppercase">Confirm New Password<span class="mandatory">*</span></label>
                                            <input type="password" class="form-control" placeholder="" formControlName="comfirmpassword" [(ngModel)]="userinfo.comfirmpassword"  >
                                            <div  class="mandatory" *ngIf="validatingForm.get('comfirmpassword').hasError('required') && validatingForm.get('comfirmpassword').touched">Confirm Password required.</div>
                                            <div  class="mandatory" *ngIf="validatingForm.get('comfirmpassword').hasError('noMatch') && validatingForm.get('comfirmpassword').touched">Password does not match.</div>

                                        </div>
                                    </div>
                               </div>
                               
                             <div class="row">
                                        <div class="col-md-12">
                                         
                                            <div class="d-flex justify-content-center mb-2">
                                                    <button class="btn btn-custom-gold-defult"  >Submit</button> &nbsp; &nbsp;
                                                    <button class="btn btn-custom-black" >Back</button>
                                            </div> 
                             </div>
                             </div>

                </form>


                </div>
        </div>
    </div>
    </div>
    </section> -->

<!-- <div  id="spinner">
     <div class="clock"></div>
    <p id="spinnertext">
            <label>Processing, Please wait...</label>
        </p>
       
    </div> -->