import { Component, OnInit, ElementRef } from '@angular/core';
import { EmailInviteService } from 'src/app/services/email-invite.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-invite',
  templateUrl: './email-invite.component.html',
  styleUrls: ['./email-invite.component.scss']
})
export class EmailInviteComponent implements OnInit {


  message1:boolean =false;  message2:boolean =false;

  constructor(private emailInviteService: EmailInviteService, private route: ActivatedRoute,private ele :  ElementRef) { }

  ngOnInit(): void {
   
    let addedUserId = this.route.snapshot.queryParamMap.get('id');

    this.verifyEmailOfUser(addedUserId)  
  }

  verifyEmailOfUser(id: any) {
    this.ele.nativeElement.querySelector('#spinner').style.visibility = 'visible';
    this.emailInviteService.verifyUserEmail(id).subscribe(res => {
      if(res.length > 0){
        if(res[0].sp_updatelogin_varification == 1){
         this.message1=true;
         this.message2=false;
        }
        if(res[0].sp_updatelogin_varification == 2){
          this.message2=true;
          this.message1=false;
        }
        this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';  
      }
  
    },
      (err) => console.log(err))
      this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';  
  }


}
