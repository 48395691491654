import { Injectable } from '@angular/core';
import { Router, CanActivate,  ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  session_data:any;
  constructor(public auth: AuthService, public router: Router, public activateRoute:ActivatedRoute) {


  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.session_data = JSON.parse(sessionStorage.getItem('petronetUserData'));
    console.log(route.data);
      const expectedRole = route.data.role;
      let expectedRoleArray = expectedRole.split(',')
    // if (!this.auth.isAuthenticated() || this.auth.getRole()!=expectedRole) {
    //   this.router.navigate(['login']);
    //   return false;
    // }

    if (!this.auth.isAuthenticated() || !expectedRoleArray.includes(this.auth.getRole())) {
      this.router.navigate(['login']);
      return false;
    }

    if(route.data.hasOwnProperty('isapprover')){
      if(route.data?.isapprover == this.session_data[0].isapprover){
        return true;
      }
      else{
        this.router.navigate(['user/assignments']);
        return false;
      } 
    }
    return true;
  }
}
