import { Component, OnInit, ElementRef } from '@angular/core';
import { LoginServiceService } from '../services/login-service.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {showInfoAlert,showRedAlert,showSuccessAlert,showWarningAlert,showConfirmAlert} from 'src/app/GlobalAlerts/global-alerts';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor(private loginservice: LoginServiceService, private ele: ElementRef) { }

  ngOnInit(): void {
    this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
    this.validation();
  }

  EmailObj = {
    emailid: '',
    password: ''
  }

  validatingForm: any;

  data: any;

  validation() {
    const emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,64}';
    this.validatingForm = new FormGroup({

      login_id: new FormControl(null, [Validators.required, Validators.pattern(emailPattern)]),



    });
  }

  submit() {
    if (this.validatingForm.invalid) {

      this.validatingForm.controls['login_id'].markAsTouched()



    }
    else {
      this.ele.nativeElement.querySelector('#spinner').style.visibility = 'visible';
      this.loginservice.checkEmail(this.EmailObj.emailid).subscribe((res: any) => {
        if (res.length == 1) {
          this.data = res;
          this.loginservice.forgotPassword(this.data).subscribe((res: any) => {
            //this.loginservice.forgotPassword( this.data[0].login_id,this.data[0].password).subscribe( (res: any) => {
            showSuccessAlert.fire({
             
              title:'Reset Password',
              html: `An email sent to <b><i>${this.EmailObj.emailid}</i></b>. Please check your email to continue. If you have not received the password reset email, please check your Spam folder.`,
              confirmButtonText: 'Ok',
              showConfirmButton: true,
              
              color: 'white',
              showClass: {
                popup: 'confirm-messege'
              },

            });
            this.EmailObj.emailid = '';
            this.validatingForm.reset();
            this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
          });


         

        }
        else {
          this.ele.nativeElement.querySelector('#spinner').style.visibility = 'hidden';
          showInfoAlert.fire({
           
            html: 'Account is not registered with this email.'
          });

        }
      })
    }


  }



}
