<div id="spinner">
        <div class="clock"></div>
        <p id="spinnertext">
                <label>Processing, Please wait...</label>
        </p>

</div>


<div class="loginbg">
        <div class="container">
                <br/>
           
              
               
                <div class="login-wrapper">

                        <div class="text-center">

                                <img src="./assets/images/logo_1.png" class="">
                               
                        </div>

                      <div style="margin:auto; width:400px">
                       
                        <asl-google-signin-button class="btn-block btn-google"  (click)="signInWithGoogle()" theme="outline" type='standard' size='large' shape='pill' width="395" logo_alignment="center"></asl-google-signin-button>
                      </div>
                      

                        <div class="mt-3 text-center">

                                <p class="text-secondary"><span><b>OR</b></span></p>
                        </div>

                        <form [formGroup]="validatingForm" class="form">

                                <div class="form-group">
                                        <label class="mb-1 ">Email </label>
                                        <input type="email" id="email" style="display:none"
                                                name="fakeusernameremembered">
                                        <input type="password" id="password" style="display:none"
                                                name="fakepasswordremembered">
                                        <input (keyup.enter)="login()" autocomplete="nope" type="email"
                                                class="form-control logintext" placeholder="Email"
                                                [(ngModel)]="userlogin.login_id" formControlName="login_id">
                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('login_id').hasError('required') && validatingForm.get('login_id').touched">
                                                Email Required.</div>
                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('login_id').hasError('pattern') && validatingForm.get('login_id').touched">
                                                Email not valid.</div>
                                </div>
                                <div class="form-group">
                                        <label class="mb-1 ">Password </label>

                                        <div class="d-flex">
                                                <input (keyup.enter)="login()" autocomplete="new-password" type="{{ showPassword ? 'text' : 'password' }}"
                                                class="form-control logintext" placeholder="Password"
                                                [(ngModel)]="userlogin.password" formControlName="password">
                                                <span class="passwordtoggle" (click)="togglePasswordVisibility()"><i class="{{showPassword ? 'fa fa-eye' : 'fa fa-eye-slash' }}" aria-hidden="true"></i></span>
                                        </div>



                                        <div class="mandatory"
                                                *ngIf="validatingForm.get('password').hasError('required') && validatingForm.get('password').touched">
                                                Password Required.</div>
                                </div>


                                <div class="form-group">

                                        <button type="submit" class="btn btn-login btn-block py-2 "
                                                (click)="login()">Login</button>
                                </div>



                        </form>
                        <div class="row">
                                <div class="col-md-12 float-right">
                                        <a [routerLink]="['/forgot-password']" class="btn-resetpassword">Forgot Password?</a>
                                </div>
                        </div>
                      
                        <div class="row mt-5">
                                <div class="col-md-12 d-flex justify-content-between">
                                        <div>
                                                <div class="form-check">
                                                        <label class="form-check-label">
                                                                <input type="checkbox" class="form-check-input mr-2"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="cbchecked">
                                                                        I agree to the terms and conditions of the 
                                                                        <a
                                                                        href="assets/pdf/license_agreement.pdf"
                                                                        target="_blank">Chalk License Agreement</a>
                                                        </label>

                                                </div>
                                        </div>
                                        <div>
                                               
                                        </div>
                                </div>

                        </div>
                        

                        <div class="row mt-5">
                                <div class="col-12 text-center">
                                        <small class="text-secondary"> &copy; 2020-{{currentYear}}<span class="text-defult-theme">
                                                        Chalk Technologies Inc.</span> All Rights Reserved.</small>
                                </div>
                        </div>
                </div>





        </div>

</div>