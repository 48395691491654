import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailInviteService {

  constructor( private http : HttpClient) { }

  sendVerificationEmail(emailObj: any): Observable < any > {
    return this.http.post(environment.hostUrl + 'send-verification-email', emailObj)
      .pipe(catchError(this.formatErrors));
  }
   
  verifyUserEmail(userId: any): Observable < any > {
    return this.http.get(environment.hostUrl + 'verify-user-email?id='+userId)
      .pipe(catchError(this.formatErrors));
  }


  private formatErrors(error: any) {
    return throwError(error.error);
  }

}
